<template>
    <div class="quot-page">
        <van-nav-bar :border="false" :title="$t('行情')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        <div class="quo-wrapper">
            <div class="robot-card" v-if="robot_info">
                <div class="robot-card_hd flex-center">
                    <img src="../../assets/pictures/kefu2.png" class="ro-logo">
                    <div v-if="robot_info.r_count > 0">{{$t('当前共有')}} {{robot_info.r_count}} {{$t('台')}}
                        {{$t('机器人自动做单中')}}
                    </div>
                    <div v-else>{{$t('您当前没有机器人正在工作')}}</div>
                </div>
                <div class="quo-body">
                    <div class="quo-item">
                        <img src="../../assets/pictures/dengji1.png" alt="">
                        <span>{{$t('当前机器人级别')}}：</span>
                        <span class="text-blue">{{$t(robot_info.robot.name)}}</span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/history-fill.png" alt="">
                        <span>{{$t('机器人有效期')}}：</span>
                        <span class="text-blue">
                            {{!robot_info.robot.use_time ? '0' :
                            (Math.floor(robot_info.robot.use_time / 86400) + ' / ' + robot_info.robot.days)}} {{$t('天')}}
                        </span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/tongxinzhuangtai2.png" alt="">
                        <span>{{$t('状态')}}：</span>
                        <span class="text-blue">
                            <template v-if="robot_info.r_count > 0">{{$t('正常')}}</template>
                            <template v-else>{{$t('停用')}}</template>
                        </span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/jifen.png" alt="">
                        <span>{{$t('做单价格')}}：</span>
                        <span class="text-blue">
                            {{!robot_info.robot.min_money ? '' :
                            (parseFloat(robot_info.robot.min_money).toFixed(2) + $t('元/单'))}}
                        </span>
                    </div>

                    <div class="quo-foot">
                        <van-button type="primary" block to="/revenue">
                            {{ $t('查看我的收益') }}
                        </van-button>
                    </div>
                </div>
            </div>
            <div class="bs-panel" style="margin-bottom:20px">
                <div class="quo-bar flex-center">
                    <div class="flex_bd flex-ex-bar">
                        <div class="expace-list">
                            <div class="ex-card" v-for="(item, index) in exchange" :key="index" >
                                <img :src="item.logo">
                            </div>
                        </div>
                    </div>
                    <div class="plus-icon" @click="show = true">
                        <van-icon name="plus" size="16"/>
                    </div>
                </div>
                <div class="quo-cells">
                    <div class="rec-cell flex-center" v-for="(item, index) in hq_info" :key="index">
                        <img :src="item.logo" class="rec-icon">
                        <div class="flex_bd">
                            <div class="text">{{item.name}}</div>
                        </div>
                        <div class="flex_bd">
                            <div class="text">{{item.close}}</div>
                        </div>
                        <div class="rec-right">
                            <!--                        <div class="text">{{item.close}}</div>-->
                            <div class="rec-per" :class="item.ratio >  0 ?'text-green':'text-red'">
                                <i class="iconfont" :class="item.ratio >= 0 ? 'icon-up':'icon-dn'"></i>
                                <span>{{parseFloat(item.ratio).toFixed(2)}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-popup class="page-popup" v-model="show" position="center" closeable :style="{ borderRadius: '10px', width:'90%'}">
            <div class="quot-dialog">
                <div class="quot-title">{{$t('前往交易所')}}</div>
                <div class="quot-body">
                    <van-row class="exp-rows" :gutter="20">
                        <van-col :span="12" v-for="(item, index) in exchange" :key="index">
                            <van-cell center clickable @click="toggle(item.url ? item.url : '')" is-link>
                                <template #title>
                                    <img :src="item.logo" alt="" class="ex-icon">
                                </template>
                            </van-cell>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                b_timer: '',
                b_load: false,
                show: false,
                name: '',
                list: [],
                hq_loading: true,
                loading: false,
                finished: false,
                page: 0,
                size: 20,
                robot_info: null,
                exchange: [],
                result: [],
                hq_info: [],
            }
        },
        computed:{
            initList(){
                const logoList = Object.entries(this.hq_info)
                if(!logoList.length){
                    return this.list
                }
                return this.list.map(item=>{
                    const num = parseInt(item.updated_at.slice(-2)) %  logoList.length
                    return {
                        ...item,
                        logo:logoList[num][1].logo
                    }
                })
            }
        },
        methods: {
            toggle(url) {
                if (url) {
                    window.open(url, '_blank');
                }
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            getB() {
                if (this.b_load) {
                    return;
                }
                this.b_load = true;
                this.$axios.get(this.$api_url.get_b).then((res) => {
                    this.hq_info = res.data;
                    this.hq_loading = false;
                    this.b_load = false;
                });
            },
            getData() {
                this.$axios
                    .get('/robot/run-info' + '?page=' + this.page)
                    .then((res) => {
                        this.robot_info = res.data.data.robot_info;
                        this.exchange = res.data.data.b_exchange;
                    });
            },
        },
        mounted() {
            this.getData();
            this.b_timer = setInterval(() => {
                this.getB()
            }, 1000);
        },
        destroyed() {
            clearInterval(this.b_timer);
        },
    }
</script>
<style scoped>
.ro-logo{
    margin-right: 6px;
    width: 30px;
}
</style>